<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>招商实施</a-breadcrumb-item>
            <a-breadcrumb-item>客户管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            v-show="permissionlist.indexOf('/business/client:新增') > -1"
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增客户
          </a-button>
          <a-button
            v-show="permissionlist.indexOf('/business/client:删除') > -1"
            type="danger"
            @click.prevent="onDetele"
          >
            删除
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['type'].value"
                  v-if="i == '客户类型'"
                  placeholder="请选择客户类型"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 公司 </a-select-option>
                  <a-select-option value="1"> 个人 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['state'].value"
                  v-else-if="i == '客户状态'"
                  style="width: 178px"
                  placeholder="请选择客户状态"
                >
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
              <!-- <a
                :style="{ marginLeft: '8px', fontSize: '12px' }"
                @click="toggle"
              >
                更多 <a-icon :type="expand ? 'up' : 'down'" />
              </a> -->
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="Clientlist"
              :rowClassName="rowClassName"
              @change="onChangetime"
            >
              <span slot="contactName" slot-scope="text, record">
                {{ record.contactName }}/{{ record.contactNum }}
              </span>
              <span style="font-family: '黑体'" slot="code" slot-scope="text"
                >{{ text }}
              </span>
              <span slot="type" slot-scope="text">
                {{ text == 0 ? '公司' : '个人' }}
              </span>
              <span slot="time" slot-scope="text, record">
                {{ text | time
                }}{{
                  record.createByName == null
                    ? ''
                    : '(' + record.createByName + ')'
                }}
              </span>
              <span slot="updateTime" slot-scope="text, record">
                {{ text | time
                }}{{
                  record.updateByName == null
                    ? ''
                    : '(' + record.updateByName + ')'
                }}
              </span>
              <span slot="status" slot-scope="text, record">
                <a-switch
                  :checked="text == 0 ? true : false"
                  default-checked
                  @change="onchangestate(record)"
                />
              </span>
              <template slot="action" slot-scope="text, record">
                <a
                  v-show="permissionlist.indexOf('/business/client:修改') > -1"
                  @click="onEdit(record)"
                  >编辑</a
                >
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <a-form-model
          style="flex-wrap: wrap"
          class="content-body flex-d-row"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="type" class="ant--input" label="客户类型">
            <a-select v-model="form.type" style="width: 100%">
              <a-select-option value="0"> 公司 </a-select-option>
              <a-select-option value="1"> 个人 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-show="form.type == 1"
            class="ant--input"
            label="姓名"
            ref="name"
            prop="name"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入姓名"
              v-model="form.name"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="form.type == 1"
            class="ant--input"
            label="姓别"
            prop="gender"
          >
            <a-select v-model="form.gender" style="width: 100%">
              <a-select-option value="0"> 女 </a-select-option>
              <a-select-option value="1"> 男 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="客户编号">
            <a-input
              disabled="true"
              v-model="form.code"
              placeholder="系统自动生成"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="name"
            prop="name"
            v-show="form.type == 0"
            class="ant--input"
            label="公司名"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入公司名"
              v-model="form.name"
            />
          </a-form-model-item>
          <!-- <a-form-model-item
            ref="name"
            prop="name"
            v-show="form.type == 0"
            class="ant--input"
            label="公司名"
          >
            <a-input-search
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
              enter-button
              style="margin-top: 4px;"
              @search="onSearchI"
              placeholder="请输入公司名"
              v-model="form.name"
            />
          </a-form-model-item> -->
          <a-form-model-item
            v-show="form.type == 1"
            class="ant--input"
            label="证件类型"
            prop="idType"
          >
            <a-select
              v-model="form.idType"
              style="width: 100%"
              :filter-option="filterOption"
              default-value="身份证"
            >
              <a-select-option value="0"> 身份证 </a-select-option>
              <a-select-option value="1"> 护照 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.type == 1"
            class="ant--input"
            label="证件号码"
            ref="idNo"
            prop="idNo"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入证件号码"
              v-model="form.idNo"
            />
          </a-form-model-item>
          <a-form-model-item
            style="width: 30%"
            ref="infoRegLocation"
            prop="infoRegLocation"
            class="ant--input"
            label="联系地址"
          >
            <a-input
              v-model="form.infoRegLocation"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入联系地址"
            />
          </a-form-model-item>
          <a-form-model-item prop="belongTo" class="ant--input" label="负责人">
            <a-select
              placeholder="请选择负责人"
              style="width: 100%"
              :filter-option="filterOption"
              v-model="form.belongTo"
              @change="onononon"
            >
              <a-select-option v-for="item in userlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-layout-content>
      <a-drawer
        width="360"
        title="添加联系人"
        :placement="right"
        :closable="false"
        :visible="visibles"
        @close="onclose"
      >
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="姓名">
            <a-input v-model="contact.name" />
          </a-form-item>
          <a-form-item label="职位">
            <a-input v-model="contact.role" />
          </a-form-item>
          <a-form-item label="手机号">
            <a-input v-model="contact.mobile" />
          </a-form-item>
          <a-form-item label="座机号">
            <a-input v-model="contact.tel" />
          </a-form-item>
          <a-form-item label="微信号">
            <a-input v-model="contact.wechat" />
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input v-model="contact.mail" />
          </a-form-item>
          <a-checkbox :checked="checked" @change="onChangecheck">
            默认联系人
          </a-checkbox>
        </a-form>
        <div class="save-draw">
          <a-button type="primary" @click="handleOk"> 保存 </a-button>
          <a-button @click="onclose" style="margin-left: 30px"> 取消 </a-button>
        </div>
      </a-drawer>
      <!-- 价格 -->
      <a-layout-content style="background: #fff; margin: 24px 0">
        <div class="content-title">
          <span>联系人信息</span>
        </div>
        <div
          style="overflow: auto; overflow-y: hidden"
          class="content-body flex-d-row"
        >
          <div
            v-for="(item, index) in form.contacts"
            :key="item.name"
            style="line-height: 65px; margin-right: 10px"
            @click="Addmobile(item, index)"
            class="body-building flex-d-column"
          >
            <a-tooltip>
              <template slot="title">
                <span>姓名:{{ item.name }}</span
                ><br />
                <span>手机号:{{ item.mobile }}</span
                ><br />
                <span>职位:{{ item.role }}</span>
              </template>
              <span>{{ item.name }}</span>
            </a-tooltip>
          </div>
          <div @click="Addmobile(undefined)" class="body-building">+</div>
        </div>
      </a-layout-content>
      <!-- 图片 -->
      <a-layout-content
        v-show="form.type == 0"
        style="background: #fff; margin-bottom: 24px"
      >
        <div class="content-title">
          <span>工商信息</span>
        </div>
        <a-form-model
          v-if="form.type == 0"
          style="flex-wrap: wrap"
          class="content-body flex-d-row"
          ref="ruleForms"
          :model="form"
          :rules="rule"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            class="ant--input"
            label="法定代表人"
            ref="infoLegalPersonName"
            prop="infoLegalPersonName"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入法定代表人"
              v-model="form.infoLegalPersonName"
            />
          </a-form-model-item>
          <a-form-model-item
            class="ant--input"
            label="统一社会信用代码"
            ref="infoCreditCode"
            prop="infoCreditCode"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入统一社会信用代码"
              v-model="form.infoCreditCode"
            />
          </a-form-model-item>
          <a-form-model-item
            class="ant--input"
            label="纳税人识别号"
            ref="infoTaxNumber"
            prop="infoTaxNumber"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入纳税人识别号"
              v-model="form.infoTaxNumber"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="组织机构代码">
            <a-input
              placeholder="请输入组织机构代码"
              v-model="form.infoOrgNumber"
            />
          </a-form-model-item>
        </a-form-model>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '客户编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '客户名称',
    dataIndex: 'name',
    // scopedSlots: { customRender: "state" },
  },
  {
    title: '客户类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '联系信息',
    dataIndex: 'contactName   ',
    scopedSlots: { customRender: 'contactName' },
  },
  {
    title: '客户状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '负责人',
    dataIndex: 'belongToName',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
    sorter: (a, b) => a * b,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    sorter: (a, b) => a * b,
    defaultSortOrder: 'descend',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import moment from 'moment'
import http from '../../../http'
export default {
  data() {
    return {
      checked: false,
      logvisible: false,
      contactindex: '',
      columns,
      title: '',
      visible: false,
      visibles: false,
      placement: 'right',
      labelCol: { span: 12 },
      wrapperCol: { span: 24 },
      permissionlist: [],
      params: {
        current: 1,
        pageSize: 10,
        type: {
          value: undefined,
          op: '=',
        },
        state: {
          value: undefined,
          op: '=',
        },
        name: {
          value: '',
          op: '%like%',
        },
        code: {
          value: '',
          op: '%like%',
        },
      },
      sorter: { update_time: 'desc' },
      total: 0,
      Clientlist: [],
      userlist: [],
      selectedRowKey: [],
      Id: '',
      contact: {
        mail: '',
        mobile: '',
        name: '',
        role: '',
        tel: '',
        wechat: '',
        isMain: 0,
      },
      textlist: ['客户编号', '客户名称', '客户类型', '客户状态'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      form: {
        id: '',
        type: '0',
        code: '',
        name: '',
        idType: '0',
        idNo: '',
        belongTo: '',
        gender: '',
        infoRegLocation: '',
        state: '',
        projectId: '',
        infoLegalPersonName: '',
        infoCreditCode: '',
        infoTaxNumber: '',
        infoOrgNumber: '',
        contacts: [],
      },
      rule: {
        infoLegalPersonName: [
          {
            required: true,
            message: '法定代表人不能为空',
            trigger: 'change',
          },
        ],
        infoCreditCode: [
          {
            required: true,
            message: '统一社会信用代码不能为空',
            trigger: 'blur',
          },
        ],
        infoTaxNumber: [
          {
            required: true,
            message: '纳税人识别号不能为空',
            trigger: 'blur',
          },
        ],
      },
      rules: {
        gender: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            message: '客户类型不能为空',
            trigger: 'change',
          },
        ],
        infoRegLocation: [
          {
            required: true,
            message: '联系地址不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },
        ],
        idType: [
          {
            required: true,
            message: '证件类型不能为空',
            trigger: 'change',
          },
        ],
        idNo: [
          {
            required: true,
            message: '证件号码不能为空',
            trigger: 'blur',
          },
        ],
        belongTo: [
          {
            required: true,
            message: '负责人不能为空',
            trigger: 'change',
          },
        ],
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
    infotime(infotime) {
      let time = Number(infotime)
      return moment(time).format('YYYY-MM-DD')
    },
  },
  methods: {
    handleSearch(e) {
      this.params.current = 1
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['name'].value = values.客户名称
        this.params['code'].value = values.客户编号
      })
      this.getClient()
    },

    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    //保存联系人
    handleOk() {
      if (this.contact.isMain == 1) {
        this.form.contacts.forEach(item => {
          item.isMain = 0
        })
      }
      if (this.contactindex == undefined) {
        this.form.contacts.push(this.contact)
        this.form.contacts[this.form.contacts.length - 1].isMain = 1
      } else {
        this.form.contacts.splice(this.contactindex, 1, this.contact)
        this.form.contacts[this.contactindex].isMain = 1
      }
      this.onclose()
    },
    onclose() {
      this.visibles = false
      this.checked = false
      this.contact = {
        mail: '',
        mobile: '',
        name: '',
        role: '',
        tel: '',
        wechat: '',
        isMain: 0,
      }
    },
    Addmobile(e, index) {
      console.log(e)
      this.visibles = true
      if (e == undefined) {
        this.contactindex = undefined
        this.contact = {
          mail: '',
          mobile: '',
          name: '',
          role: '',
          tel: '',
          isMain: '',
          wechat: '',
        }
      } else {
        this.contactindex = index
        this.contact = e
      }
      if (e.isMain == 1) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    onChangecheck(e) {
      this.checked = e.target.checked
      if (e.target.checked == true) {
        this.contact.isMain = 1
      } else {
        this.contact.isMain = 0
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该客户么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteClient()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteClient() {
      try {
        const res = await http.deleteClient(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    properyChange(value) {
      //   console.log(value);
      this.form.projectId = value
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form = {
        id: '',
        type: '0',
        code: '',
        name: '',
        idType: '0',
        idNo: '',
        belongTo: '',
        gender: '',
        infoRegLocation: '',
        state: '',
        projectId: '',
        infoLegalPersonName: '',
        infoCreditCode: '',
        infoTaxNumber: '',
        infoOrgNumber: '',
        contacts: [],
      }
    },
    onSubmit() {
      if (this.form.type == 0) {
        this.$refs.ruleForms.validate(valid => {
          if (valid) {
            console.log()
          } else {
            return false
          }
        })
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.Id == '') {
            this.AddClient(this.form)
          } else if (this.Id != '') {
            this.putClient(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      //   console.log(this.form.id);
      this.visible = true
      this.title = '编辑'
      this.getClientId(item.id)
    },
    async putClient(form) {
      try {
        const res = await http.putClient(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },

    onpagesize(e) {
      this.params.current = e
      this.getClient()
    },
    onononon(e) {
      console.log(e)
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      this.getuserlist()
      this.form.belongTo =
        JSON.parse(localStorage.getItem('store-userlist')).id + ''
      if (JSON.parse(localStorage.getItem('store-userlist')) == null) {
        this.logvisible = true
      }
    },
    async getClient() {
      try {
        const res = await http.getClient(this.params, this.sorter)
        const { success, data } = res.data
        if (success) {
          this.Clientlist = data.clients.records
          this.total = data.clients.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getClientId(id) {
      try {
        const res = await http.getClientId(id)
        this.getuserlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.client.type == 0) {
            this.form.type = '0'
            this.form.infoLegalPersonName = data.client.infoLegalPersonName
            this.form.infoCreditCode = data.client.infoCreditCode
            this.form.infoTaxNumber = data.client.infoTaxNumber
            this.form.infoOrgNumber = data.client.infoOrgNumber
          } else {
            this.form.type = '1'
            this.form.idType = data.client.idType + ''
            this.form.gender = data.client.gender + ''
            this.form.idNo = data.client.idNo
          }
          this.form.name = data.client.name
          this.form.code = data.client.code
          this.form.belongTo = data.client.belongTo
          this.form.contacts = data.client.contacts
          this.form.infoRegLocation = data.client.infoRegLocation
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async AddClient(form) {
      try {
        const res = await http.AddClient(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getuserlist() {
      try {
        const res = await http.getuserlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.userlist = data.users
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onChangetime(pagination, filters, sorter) {
      //   console.log(sorter);
      if (sorter.order == 'descend') {
        if (sorter.field == 'updateTime') {
          this.sorter['update_time'] = 'desc'
        } else {
          this.sorter['create_time'] = 'desc'
        }
      } else if (sorter.order == 'ascend') {
        if (sorter.field == 'updateTime') {
          this.sorter['update_time'] = 'asc'
        } else {
          this.sorter['create_time'] = 'asc'
        }
      } else {
        return
      }
      this.getClient()
    },
    timer() {
      return setTimeout(() => {
        this.getClient()
      }, 500)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.Stateclient(e.id, this.form.state)
    },
    async Stateclient(id, state) {
      try {
        const res = await http.Stateclient(id, state)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    this.getClient()
    this.getuserlist()
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 11 : 7
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 700px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-btn {
  margin: 0px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
  .ant-form-item {
    height: 90px;
    margin: 0;
    padding-right: 20px;
  }
  .body-building {
    min-width: 65px;
    line-height: 65px;
    min-height: 65px;
    text-align: center;
    border: 1px dashed #999;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px dashed rgb(197, 172, 29);
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.save-draw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eaeaea;
}

.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.ant--input {
  width: 25%;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
td:nth-child(1) {
  width: 150px;
  background-color: #e4eef6;
}
td:nth-child(2) {
  width: 400px;
  background-color: #fff;
}
td:nth-child(3) {
  width: 280px;
  background-color: #e4eef6;
}
td:nth-child(4) {
  width: 350px;
  background-color: #fff;
}
td:nth-child(5) {
  width: 230px;
  background-color: #e4eef6;
}
td:nth-child(6) {
  width: 220px;
  background-color: #fff;
}
td {
  padding: 8px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
</style>
